import { DESKTOP_1280, MOBILE_575, TABLET_768 } from '@global/styles/sizes';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 26px;
  row-gap: 30px;

  @media (max-width: ${DESKTOP_1280}px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${MOBILE_575}px) {
    grid-template-columns: 1fr;
  }
`;

export const DateTextStyles = styled.div`
  font: normal normal normal 14px/16px Poppins;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 5px;
  color: #161616;
`;
