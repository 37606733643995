/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import NoResultFilter from '@components/NoResultFilter/NoResultFilter';
import {
  handleFilterArticles,
  handleFilterViaQueryParam,
  handleSearchViaQueryParam,
} from 'helpers/resourceCenterHelper';
import BlogCard from '@sections/ArticlesList/components/BlogCard/BlogCard';

import {
  CheckboxInput,
  FilterButton,
  FilterContainer,
  FilterInput,
  FilterItemsList,
  FiltersContainer,
  FilterTitle,
  FilterWrapper,
  IconContainer,
  RightContainer,
  SearchContainer,
  SearchImage,
  SearchInput,
  TitleFilterContainer,
} from '@sections/ArticlesList/articleList.styles';
import ShareLink from '@sections/ArticlesList/components/Sharelink';
import { BlogsListProps, BlogsProps } from './blogList.types';
import SearchIcon from './assets/search-icon.svg';
import { Container, Wrapper } from './blogList.styles';
import ArrowIcon from './assets/ArrowIcon';

const BlogList = ({
  blogs,
  topicsFilter,
  pathfulModuleFilter,
  rolesFilter,
  typesFilter,
}: BlogsListProps) => {
  const [pathfulModuleFilterOpen, setPathfulModuleFilterOpen] = useState<boolean>(false);
  const [topicFilterOpen, setTopicFilterOpen] = useState<boolean>(false);
  const [typeFilterOpen, setTypeFilterOpen] = useState<boolean>(false);

  const [roleFilterOpen, setRoleFilterOpen] = useState<boolean>(false);
  const [filterApplied, setFilterApplied] = useState<string[]>([]);
  const [renderBlogs, setRenderBlogs] = useState(blogs);
  const [searchInput, setSearchInput] = useState('');
  const [confirmSearchInput, setConfirmSearchInput] = useState('');
  const [renderFilters, setRenderFilters] = useState(false);
  const [renderTooltip, setRenderTooltip] = useState(false);
  const filterWrapperRef = useRef<HTMLDivElement>(null);

  const handleFilterApplied = (value: string) => {
    if (filterApplied.find((filter) => filter === value)) {
      setFilterApplied(filterApplied.filter((filter) => filter !== value));
    } else {
      setFilterApplied([...filterApplied, value]);
    }
  };

  const handleSearch = (arrayBlogs: BlogsProps[]) => {
    if (confirmSearchInput !== '') {
      const filteredBlogs = arrayBlogs.filter((renderBlog) => {
        return renderBlog.attributes.Title.toLowerCase().includes(confirmSearchInput.toLowerCase());
      });
      setRenderBlogs([...new Set(filteredBlogs)]);
    } else {
      setRenderBlogs([...new Set(arrayBlogs)]);
    }
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (filterWrapperRef.current && !filterWrapperRef.current.contains(event.target as Node)) {
      setPathfulModuleFilterOpen(false);
      setTopicFilterOpen(false);
      setRoleFilterOpen(false);
      setTypeFilterOpen(false);
    }
  }, []);

  useEffect(() => {
    if (filterApplied.length > 0) {
      const blogsToRender = handleFilterArticles(blogs as BlogsProps[], filterApplied);
      handleSearch(blogsToRender);
    } else if (confirmSearchInput !== '') {
      handleSearch(blogs);
    }
    if (filterApplied.length === 0 && confirmSearchInput === '') {
      setRenderBlogs(blogs);
    }
  }, [blogs, filterApplied, confirmSearchInput]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    handleFilterViaQueryParam(urlParams, setFilterApplied);
    handleSearchViaQueryParam(urlParams, setConfirmSearchInput);

    const input = document.getElementById('searchInput');
    if (input) {
      input.onkeydown = (event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          setConfirmSearchInput(event.target?.value);
        }
      };
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setRenderTooltip(false);
    }, 3000);
  }, [renderTooltip]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <GlobalWrapper>
      <Container>
        <FiltersContainer>
          <FilterButton onClick={() => setRenderFilters(!renderFilters)}>Filters</FilterButton>
          <RightContainer>
            <SearchContainer>
              <SearchInput
                id="searchInput"
                placeholder="Search"
                type="text"
                value={searchInput}
                onChange={(event) => setSearchInput(event.target.value)}
              />
              <SearchImage
                src={SearchIcon}
                alt="Search"
                onClick={() => setConfirmSearchInput(searchInput)}
              />
            </SearchContainer>
          </RightContainer>
          <FilterWrapper renderFilters={renderFilters} ref={filterWrapperRef}>
            {(pathfulModuleFilter.length > 0 ||
              topicsFilter.length > 0 ||
              rolesFilter.length > 0 ||
              typesFilter.length > 0) && (
              <TitleFilterContainer>
                <FilterTitle>Filters resources:</FilterTitle>
              </TitleFilterContainer>
            )}
            {pathfulModuleFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setPathfulModuleFilterOpen(!pathfulModuleFilterOpen);
                    setTopicFilterOpen(false);
                    setRoleFilterOpen(false);
                    setTypeFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Pathful Module
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={pathfulModuleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {pathfulModuleFilterOpen &&
                    pathfulModuleFilter.map((module, index) => (
                      <FilterInput
                        key={module.attributes.Pathful_Module}
                        lastChild={pathfulModuleFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={module.attributes.Pathful_Module}
                          value={module.attributes.Pathful_Module}
                          checked={filterApplied.includes(module.attributes.Pathful_Module)}
                          onChange={() => handleFilterApplied(module.attributes.Pathful_Module)}
                          type="checkbox"
                        />
                        {module.attributes.Pathful_Module}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
            {topicsFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setTopicFilterOpen(!topicFilterOpen);
                    setRoleFilterOpen(false);
                    setPathfulModuleFilterOpen(false);
                    setTypeFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Topics
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={topicFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {topicFilterOpen &&
                    topicsFilter.map((topic, index) => (
                      <FilterInput
                        key={topic.attributes.Topic}
                        lastChild={topicsFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={topic.attributes.Topic}
                          value={topic.attributes.Topic}
                          checked={filterApplied.includes(topic.attributes.Topic)}
                          onChange={() => handleFilterApplied(topic.attributes.Topic)}
                          type="checkbox"
                        />
                        {topic.attributes.Topic}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
            {rolesFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setRoleFilterOpen(!roleFilterOpen);
                    setPathfulModuleFilterOpen(false);
                    setTopicFilterOpen(false);
                    setTypeFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Role
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={roleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {roleFilterOpen &&
                    rolesFilter.map((role, index) => (
                      <FilterInput
                        key={role.attributes.Role}
                        lastChild={rolesFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={role.attributes.Role}
                          value={role.attributes.Role}
                          checked={filterApplied.includes(role.attributes.Role)}
                          onChange={() => handleFilterApplied(role.attributes.Role)}
                          type="checkbox"
                        />
                        {role.attributes.Role}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
            {typesFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setTypeFilterOpen(!typeFilterOpen);
                    setRoleFilterOpen(false);
                    setPathfulModuleFilterOpen(false);
                    setTopicFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Type
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={typeFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {typeFilterOpen &&
                    typesFilter.map((type, index) => (
                      <FilterInput
                        key={type.attributes.Type}
                        lastChild={typesFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={type.attributes.Type}
                          value={type.attributes.Type}
                          checked={filterApplied.includes(type.attributes.Type)}
                          onChange={() => handleFilterApplied(type.attributes.Type)}
                          type="checkbox"
                        />
                        {type.attributes.Type}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
          </FilterWrapper>
        </FiltersContainer>
        {(filterApplied.length > 0 || confirmSearchInput !== '') && (
          <ShareLink
            filterApplied={filterApplied}
            searchInput={confirmSearchInput}
            handleFilterApplied={handleFilterApplied}
            handleConfirmSearchInput={setConfirmSearchInput}
            articleHaveResult={renderBlogs.length > 0}
          />
        )}
        <Wrapper>
          {renderBlogs.length > 0 ? (
            renderBlogs.map((blog) => <BlogCard blog={blog} />)
          ) : (
            <NoResultFilter />
          )}
        </Wrapper>
      </Container>
    </GlobalWrapper>
  );
};

export default BlogList;
