import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { StaticQuery, graphql } from 'gatsby';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import {
  DataContainerSkeleton,
  LoadingSkeleton,
  LoadingImageSkeleton,
} from '@sections/ResourcesCenterHero/resourcesCenterHero.styles';
import {
  BlogContainer,
  BlogTitle,
  BlogTitleContainer,
  BlogTopic,
  BlogWrapper,
  Container,
  DataContainer,
  HeroWrapper,
  ImageContainer,
  Tag,
  TitleContainer,
} from './blogsIndexHero.styles';
import { BlogsIndexHeroProps } from './blogsIndexHero.types';

const BlogIndexHeroComponent = ({ data }: BlogsIndexHeroProps) => {
  const spotlightBlogs = data.cms.blogs.data
    .sort((blog1, blog2) => {
      return new Date(blog1.attributes.Publication_Date) <
        new Date(blog2.attributes.Publication_Date)
        ? 1
        : -1;
    })
    .slice(0, 4);

  const skeletonComponent = () => {
    return Array.from({ length: 4 }, () => (
      <BlogWrapper>
        <DataContainerSkeleton className="data-container">
          <Tag>
            <LoadingSkeleton />
          </Tag>
          <BlogTitle>
            <LoadingSkeleton count={3} isFirstLineSkeleton className="secondary-title" />
          </BlogTitle>
          <LoadingSkeleton />
        </DataContainerSkeleton>
        <ImageContainer>
          <LoadingImageSkeleton />
        </ImageContainer>
      </BlogWrapper>
    ));
  };

  return (
    <Container>
      <GlobalWrapper>
        <TitleContainer>Blogs</TitleContainer>
        {!data && spotlightBlogs.length === 0 ? (
          <BlogContainer>{skeletonComponent().map((component) => component)}</BlogContainer>
        ) : (
          <HeroWrapper>
            <BlogContainer>
              {spotlightBlogs.map((blog) => (
                <BlogWrapper
                  href={`${PATHS.BLOG}/${transformToSlug(blog.attributes.slug.toLowerCase())}`}
                >
                  <DataContainer className="data-container">
                    <Tag>Blog</Tag>
                    <BlogTitleContainer>
                      <BlogTitle>{blog.attributes.Title}</BlogTitle>
                    </BlogTitleContainer>
                    <BlogTopic
                      href={`${PATHS.RESOURCE_CENTER}/?filter=${
                        blog.attributes?.Primary_Field || ''
                      }`}
                    >
                      {blog.attributes?.Primary_Field}
                    </BlogTopic>
                  </DataContainer>
                  <ImageContainer>{CMSParseImage(blog.attributes.Small_Image)}</ImageContainer>
                </BlogWrapper>
              ))}
            </BlogContainer>
          </HeroWrapper>
        )}
      </GlobalWrapper>
    </Container>
  );
};

const BlogIndexHero = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          cms {
            blogs(filters: { Spotlight_Blog: { eq: true }, Hide_Blog: { eq: false } }) {
              data {
                attributes {
                  slug
                  Title
                  Small_Image {
                    data {
                      attributes {
                        alternativeText
                        url
                        mime
                        urlJSON
                      }
                    }
                  }
                  Primary_Field
                  Topics {
                    data {
                      attributes {
                        Topic
                      }
                    }
                  }
                  Type_Filters {
                    data {
                      attributes {
                        Type
                      }
                    }
                  }
                  Pathful_Modules {
                    data {
                      attributes {
                        Pathful_Module
                      }
                    }
                  }
                  Roles {
                    data {
                      attributes {
                        Role
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <BlogIndexHeroComponent data={data} />}
    />
  );
};

export default BlogIndexHero;
